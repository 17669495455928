<template>
    <div>
         <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-cog"></i> Automation Log</h2>
        <div class="row">
            <div class="col-12 col-lg-12 pt-3">
                <ejs-grid
                    ref="grid"
                    id="Grids"
                    :dataSource="data"
                    :excelExportComplete='exportComplete'
                    :pdfExportComplete='exportComplete' :allowFiltering="true" :allowExcelExport="true"
                    :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings"
                    :loadingIndicator='loadingIndicator'

                    :filterSettings="filterSettings" :csvExportComplete='exportComplete'
                    :detailTemplate ="detailTemplate"
                >
                    <e-columns>

                        <e-column field='created_ts' headerText='Date Created'
                                  type='date'
                                  format='yyyy-MM-dd HH:mm:ss'
                                  :visible="visibleColumns.includes('created')"
                        ></e-column>
                        <e-column field='updatedby' headerText='Updated By'

                                  :visible="visibleColumns.includes('updatedBy')"
                        ></e-column>
                        <e-column field='modified_ts' headerText='Date Updated'
                                  type='date'
                                  format='yyyy-MM-dd HH:mm:ss'
                                  :visible="visibleColumns.includes('timeUpdate')"
                        ></e-column>
                        <e-column field='reasonForUpdate' headerText='Reason For Update'
                                  :visible="visibleColumns.includes('reasonForUpdate')"
                        ></e-column>



                    </e-columns>
                    <e-aggregates>
                        <e-aggregate>
                            <e-columns>
                                <e-column type="Sum" field="lastcounter" format="N" :footerTemplate='footerSum'></e-column>
                            </e-columns>
                        </e-aggregate>
                    </e-aggregates>



                </ejs-grid>

                <ejs-pager :pageSize='pageSettings.pageSize' :pageCount='10'
                           :totalRecordsCount='pageSettings.totalRecordsCount'
                           @click="onPageClick"
                >
                </ejs-pager>
            </div>
        </div>

    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import { GridPlugin, Page, PdfExport, ExcelExport, Sort,Group, Aggregate, Filter,Toolbar,PagerPlugin,DetailRow} from '@syncfusion/ej2-vue-grids';
// import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";
import axios from "axios";


Vue.use(GridPlugin);
Vue.use(PagerPlugin)
export default {
    name: " automationlog",
    components: {},
    props: {
        user: {
            type: Object,
            required: false
        },
    },
    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter,PdfExport,Toolbar,Group,DetailRow ]
    },
    // props:['summary'],
    data: function() {
        return {
            detailTemplate: function () {
                return { template : Vue.component('detailTemplate',{
                        template: `
             <div class="table-container m-5">
                   <table class="detailtable table table-bordered" width="100%">
                  <thead class="table-dark">
                    <tr>
                    <th class="text-center">Old Settings</th>
                      <th class="text-center">New Settings</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                          <p>Week Day: {{data.oldvalue.weekDay}}</p>
                          <p>Name: {{data.oldvalue.name}}</p>
                          <p>Shown Rate: {{data.oldvalue.shownRate}}</p>
                          <p>Week Day: {{data.oldvalue.needMax}}</p>
                          <p>Name: {{data.oldvalue.needLimit}}</p>
                          <p>Shown Rate: {{data.oldvalue.type}}</p>
                          <p>Shown Rate: {{data.oldvalue.queueCheckIP}}</p>
                      </td>
                      <td>
                          <p>Week Day: {{data.weekDay}}</p>
                          <p>Name: {{data.name}}</p>
                          <p>Shown Rate: {{data.shownRate}}</p>
                          <p>Week Day: {{data.needMax}}</p>
                          <p>Name: {{data.needLimit}}</p>
                          <p>Shown Rate: {{data.type}}</p>
                          <p>Shown Rate: {{data.queueCheckIP}}</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
            </div>`,
                        data: function() {
                            return {
                                data: {}
                            }
                        },
                    })}
            },
            loadingIndicator: { indicatorType: 'Shimmer' },
            selectedColumns:["created","timeUpdate",'reasonForUpdate','updatedBy'], // Set the initial selected columns
            visibleColumns: ["created","timeUpdate",'reasonForUpdate','updatedBy'],
            groupSettings:  {
                showDropArea: true,
                disablePageWiseAggregates: true,
            },
            counter: this.details,
            counterCheck: 0,
            counerCheckTwo:1,
            data: null,
            // data: new DataManager({
            //     url: `${this.$root.serverUrl}/admin/reports/incidentsReport`,
            //     adaptor: new WebApiAdaptor(),
            //     crossDomain: true,
            //     headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            // }),
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            repo:[],
            report:[],
            reports:[],
            proxieDataSet: [],
            filter:{
                to: '',
                from: '',
                status:'' ,
                provider:'',
                page:'',

            },
            toolbar: ['CsvExport','ExcelExport', 'PdfExport'],
            modalTitle:"Modal Title",
            modalShow:false,
            pageSizes:[10,25,50,75],
            pageSettings: {pageSize: 10},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
        }
    },
    created() {
        this.$root.preloader = false;
        this.loading()
        this.data.timezone =  this.$store.state.user.timezone
    },
    methods:{

        onPageClick(args) {
            // Update the current page when the user clicks on the pager
            this.filter.page = args.currentPage;
            this.pageSettings.currentPage = args.currentPage;
            this.loading();
        },
        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.name})`;
            this.modalShow = true;
        },
        customAggregateFn : function (data) {
            return data.result.filter((item) => item.error).length;
        },
        loading:function(){

            console.log('this is from the loading area')

            //fills the report array with the needed data
            // this.data = [];

            //this.$refs.griditem.showSpinner = true;


            const request = {
                'apikey': this.$root.apikey,
                filters: {
                    start: new Date(),
                    end: new Date()
                }
            };


            request.pagesize = this.pageSettings.pageSize
            if (this.filter.page) request.filters.page = this.filter.page;

            axios.get(`${this.$root.serverUrl}/admin/RuleEngine`, {params: request}).then(function (resp) {
                //Store the stats
                if(resp.data)resp.data.Items = resp.data.Items.filter(item => item.updatedby !== undefined);
                // console.log(d)
                // const filteredData = data.filter(item => item.updatedBy !== null && item.updatedBy !== undefined);
                let count = (resp.data) ? resp.data.Items.length: 0;
                let data = (resp.data) ? resp.data.Items : [];
                if (resp.data.error) {
                    swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                } else {
                    this.data = data
                    // {result:data, count:count}
                    this.pageSettings.totalRecordsCount = count;
                    this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);

                }
                this.$refs.griditem.hideSpinner = true;
            }.bind(this)).catch(() => {
                this.reportLoading = false;
            });


        },
        toolbarClick: function(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            alert(args.item.text)
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
                case 'CSV Export':
                    (this.$refs.grid).csvExport(this.getCsvExportProperties());
                    break;
            }
        },
        getCsvExportProperties: function() {
            return {
                fileName: "exported_data.csv"
            };
        },
        getExcelExportProperties: function() {
            return {
                fileName: "exported_data.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "exported_data.pdf"
            };
        },

    },
}
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';

@import "../../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";


body {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    background-color: #f8f9fa;
}
.table-container {
    max-width: 600px;
    padding: 20px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    background-color: white;
}
</style>

